import React from 'react'
import AsideMenuData from './AsideMenuData'

const AsideMenu: React.FC = () => {
  return (
    <div className='pb-20 mt-5 mb-20 overflow-y-scroll no-scrollbar'>
      <AsideMenuData />
    </div>
  )
}

export {AsideMenu}
