import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const LoginEvenReportScreen = {
  LOGIN_EVEN_REPORT: {
    PATH: '/report/login-event-report',
    TITLE: 'Login Event Report',
    TITLE_ALT: 'Login Event Report',
    breadcrumbs: defaultBreadcrumbs,
  },
}
