import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosMenuTemplateScreen = {
  POS_MENU_TEMPLATE: {
    PATH: '/master-data/menu-template',
    TITLE: 'Menu Template',
    TITLE_ALT: 'Menu Template',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_MENU_TEMPLATE_ADD: {
    PATH: '/master-data/menu-template/add',
    TITLE: 'Add Menu Template',
    TITLE_ALT: 'Add Template',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_MENU_TEMPLATE_EDIT: {
    PATH: '/master-data/menu-template/edit/:id',
    TITLE: 'Edit Menu Template',
    TITLE_ALT: 'Edit Template',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_MENU_TEMPLATE_DETAIL: {
    PATH: '/master-data/menu-template/detail/:id',
    TITLE: 'Detail Menu Template',
    TITLE_ALT: 'Detail Template',
    breadcrumbs: defaultBreadcrumbs,
  },
}
