import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosMenuScreen = {
  POS_MENU: {
    PATH: '/master-data/menu',
    TITLE: 'Menu ',
    TITLE_ALT: 'Menu',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_MENU_ADD: {
    PATH: '/master-data/menu/add',
    TITLE: 'Add Menu ',
    TITLE_ALT: 'Add Menu',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_MENU_EDIT: {
    PATH: '/master-data/menu/edit/:id',
    TITLE: 'Edit Menu ',
    TITLE_ALT: 'Edit Menu',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_MENU_DETAIL: {
    PATH: '/master-data/menu/detail/:id',
    TITLE: 'Detail Menu ',
    TITLE_ALT: 'Detail Menu',
    breadcrumbs: defaultBreadcrumbs,
  },
}
