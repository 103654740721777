import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosVoucherScreen = {
  POS_VOUCHER: {
    PATH: '/master-data/voucher',
    TITLE: 'Voucher',
    TITLE_ALT: 'Voucher',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_VOUCHER_ADD: {
    PATH: '/master-data/voucher/add',
    TITLE: 'Add Voucher',
    TITLE_ALT: 'Add Voucher',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_VOUCHER_EDIT: {
    PATH: '/master-data/voucher/edit/:id',
    TITLE: 'Edit Voucher',
    TITLE_ALT: 'Edit Voucher',
  },
  POS_VOUCHER_DETAIL: {
    PATH: '/master-data/voucher/detail/:id',
    TITLE: 'Edit Voucher',
    TITLE_ALT: 'Edit Voucher',
  },
}
