import {defaultBreadcrumbs, generateBreadcrumb} from 'src/app/models/screen-prop'

const UserRoleScreens = {
  USER_ROLE: {
    PATH: '/permissions',
    TITLE: 'Permissions',
    TITLE_ALT: 'Permissions',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_USER: {
    PATH: '/permissions/user/list',
    HEADER_NAME: 'Permissions',
    TITLE: 'Users List',
    TITLE_ALT: 'Users List',
    PERMISSION: 'user.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_USER: {
    PATH: '/permissions/user/list/add',
    TITLE: 'Add New User',
    TITLE_ALT: 'Add New User',
    PERMISSION: 'user.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_USER: {
    PATH: '/permissions/user/list/edit/:id',
    TITLE: 'Edit User',
    TITLE_ALT: 'Edit User',
    PERMISSION: 'user.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_USER: {
    PATH: '/permissions/user/list/detail/:id',
    TITLE: 'Detail User',
    TITLE_ALT: 'Detail User',
    PERMISSION: 'user.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_ROLE: {
    PATH: '/permissions/role/list',
    HEADER_NAME: 'Permissions',
    TITLE: 'User Roles',
    TITLE_ALT: 'User Roles',
    PERMISSION: 'user_roles.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_ROLE: {
    PATH: '/permissions/role/list/add',
    TITLE: 'Add Role',
    TITLE_ALT: 'Add Role',
    PERMISSION: 'user_roles.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_ROLE: {
    PATH: '/permissions/role/list/edit/:id',
    TITLE: 'Edit Role',
    TITLE_ALT: 'Edit Role',
    PERMISSION: 'user_roles.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_ROLE: {
    PATH: '/permissions/role/list/detail/:id',
    TITLE: 'Detail Role',
    TITLE_ALT: 'Detail Role',
    PERMISSION: 'user_roles.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}

const userBreadcrumbs = generateBreadcrumb([UserRoleScreens.LIST_USER])
const roleBreadcrumbs = generateBreadcrumb([UserRoleScreens.LIST_ROLE])

UserRoleScreens.ADD_USER.breadcrumbs = () => userBreadcrumbs
UserRoleScreens.EDIT_USER.breadcrumbs = () => userBreadcrumbs
UserRoleScreens.DETAIL_USER.breadcrumbs = () => userBreadcrumbs
UserRoleScreens.ADD_ROLE.breadcrumbs = () => roleBreadcrumbs
UserRoleScreens.EDIT_ROLE.breadcrumbs = () => roleBreadcrumbs
UserRoleScreens.DETAIL_ROLE.breadcrumbs = () => roleBreadcrumbs

export default UserRoleScreens
