import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosStationScreen = {
  POS_STATION: {
    PATH: '/master-data/station',
    TITLE: ' Station',
    TITLE_ALT: 'Station',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_STATION_ADD: {
    PATH: '/master-data/station/add',
    TITLE: 'Add Station',
    TITLE_ALT: 'Add Station',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_STATION_EDIT: {
    PATH: '/master-data/station/edit/:id',
    TITLE: 'Add Station',
    TITLE_ALT: 'Add Station',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_STATION_DETAIL: {
    PATH: '/master-data/station/detail/:id',
    TITLE: 'Detail Station',
    TITLE_ALT: 'Detail Station',
    breadcrumbs: defaultBreadcrumbs,
  },
}
