import {defaultBreadcrumbs} from 'src/app/models/screen-prop'
import {OrderScreen} from './pages/PurchaseOrder/Screen'
import {RequestScreen} from './pages/PurchaseRequest/Screen'

const PurchasingScreen = {
  PURCHASING: {
    PATH: '/purchasing',
    TITLE: 'Purchasing',
    TITLE_ALT: 'Purchasing',
    breadcrumbs: defaultBreadcrumbs,
  },

  ...RequestScreen,
  ...OrderScreen,
}

export default PurchasingScreen
