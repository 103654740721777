import {CreateNewPassword} from './page/CreateNewPassword/CreateNewPassword'
import ForgotPassword from './page/ForgotPassword/ForgotPassword'
import LoginEmail from './page/LoginEmail/LoginEmail'

const AuthScreens = {
  LOGIN_EMAIL: {
    COMPONENT: LoginEmail,
    PATH: '/auth/login-email',
  },
  FORGOT: {
    COMPONENT: ForgotPassword,
    PATH: '/auth/forgot-password',
  },
  CREATE_PASSWORD: {
    COMPONENT: CreateNewPassword,
    PATH: '/auth/create-password',
  },
}

export default AuthScreens
