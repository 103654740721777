import {cva} from 'class-variance-authority'

export const iconButton = cva(
  ['outline-0', 'border-0', 'p-0', 'w-8', 'h-8', 'border', 'rounded-lg'],
  {
    variants: {
      colors: {
        primary: ['bg-primary-light-2', 'text-primary', 'border-primary-100'],
        success: ['bg-success-light-2', 'text-success'],
        danger: ['bg-danger-light-2', 'text-danger', 'border-danger-100'],
        warning: ['bg-[#FCF5E3]', 'text-warning'],
        info: ['bg-info-light-2', 'text-info'],
        neutral: ['bg-neutral-20', 'text-neutral-60'],
        white: ['bg-white', 'text-danger'],
      },
    },
    compoundVariants: [
      {
        colors: 'primary',
      },
    ],
    defaultVariants: {
      colors: 'primary',
    },
  }
)
