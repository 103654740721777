import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosVisitorTypeScreen = {
  POS_VISITOR_TYPE: {
    PATH: '/master-data/visitor-type',
    TITLE: 'Visitor Type',
    TITLE_ALT: 'Visitor Type',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_VISITOR_TYPE_ADD: {
    PATH: '/master-data/visitor-type/add',
    TITLE: 'Add Visitor Type',
    TITLE_ALT: 'Add Visitor Type',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_VISITOR_TYPE_EDIT: {
    PATH: '/master-data/visitor-type/edit/:id',
    TITLE: 'Edit Visitor Type',
    TITLE_ALT: 'Edit Visitor Type',
  },
  POS_VISITOR_TYPE_DETAIL: {
    PATH: '/master-data/visitor-type/detail/:id',
    TITLE: 'Detail Visitor Type',
    TITLE_ALT: 'Detail Visitor Type',
  },
}
