import {Mutex} from 'async-mutex'
import axios, {AxiosStatic} from 'axios'
import AuthRedux from 'src/app/modules/auth/redux/AuthRedux'

const mutex = new Mutex()
const plainAxios = axios.create()

export default function setupAxios(localAxios: AxiosStatic, store: any) {
  localAxios.defaults.baseURL = process.env.REACT_APP_API_URL
  plainAxios.defaults.baseURL = process.env.REACT_APP_API_URL

  localAxios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {access_token},
      } = store.getState()

      if (access_token && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${access_token}`
      }

      if (config.method === 'get' && config.params) {
      Object.keys(config.params).forEach(key => {
        if (config.params[key] === '' || config.params[key] === null || config.params[key] === undefined) {
          delete config.params[key];
        }
      });
    }

      return config
    },
    (err: any) => {
      Promise.reject(err)
    }
  )

  localAxios.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      const {
        auth: {refresh_token},
      } = store.getState()
      const {dispatch} = store

      const {status} = error.response ?? {}

      if (status === 401) {
        return mutex.runExclusive(async () => {
          const refresh = await _refreshToken(refresh_token)

          if (refresh) {
            error.config.headers.Authorization = `Bearer ${store.getState().auth.access_token}`
            try {
              return await plainAxios.request(error.config)
            } catch (err: any) {
              if (err.response?.status === 401) dispatch(AuthRedux.actions.logout())
              throw err
            }
          }
          dispatch(AuthRedux.actions.logout())
          throw error
        })
      }
      if (status === 403) {
        dispatch(AuthRedux.actions.logout())
      }
      throw error
    }
  )

  const _refreshToken = async (refresh_token: any) => {
    const {dispatch} = store
    try {
      const result = await plainAxios.post(`/api/auth/refresh-token`, null, {
        headers: {
          Authorization: `Bearer ${refresh_token}`,
        },
      })
      if (result.data)
        dispatch(
          AuthRedux.actions.fulfillToken({
            access_token: result.data.response_output?.detail.token,
            refresh_token: refresh_token,
          })
        )
      return result.data
    } catch (error) {
      dispatch(AuthRedux.actions.logout())
      throw error
    }
  }
}
