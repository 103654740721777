import {cva} from 'class-variance-authority'

export const chipTheme = cva(['py-[2px]', 'px-2', 'rounded-lg', 'font-semibold', 'text-fs-10'], {
  variants: {
    variant: {
      primary: ['bg-primary-light-2', 'text-primary'],
      success: ['bg-[#EFFCE3]', 'text-[#0B6417]'],
      danger: ['bg-danger-light-2', 'text-danger'],
      warning: ['bg-warning-light-2', 'text-warning'],
      info: ['bg-info-light-2', 'text-info'],
      neutral: ['bg-neutral-20', 'text-gray-600'],
      pending: ['bg-[#FEF3F2] text-[#AC6D01]'],
      deleted: ['bg-[#FEF3F2] text-[#890D14]'],
      available: ['bg-[#E3F5FC] text-[#0A3D87]'],
      expired: ['bg-[#FEF3F2] text-[#0A3D87]'],
      'alt-info': ['bg-[#E3F5FC] text-[#890D14]'],
    },
  },
  compoundVariants: [
    {
      variant: 'success',
    },
  ],
  defaultVariants: {
    variant: 'success',
  },
})
