import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosPromotionScreen = {
  POS_PROMOTION: {
    PATH: '/master-data/promotion',
    TITLE: 'Promotion',
    TITLE_ALT: 'Promotion',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_PROMOTION_ADD: {
    PATH: '/master-data/promotion/add',
    TITLE: 'Add Promotion',
    TITLE_ALT: 'Add Promotion',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_PROMOTION_EDIT: {
    PATH: '/master-data/promotion/edit/:id',
    TITLE: 'Edit Promotion',
    TITLE_ALT: 'Edit Promotion',
  },
  POS_PROMOTION_DETAIL: {
    PATH: '/master-data/promotion/detail/:id',
    TITLE: 'Detail Promotion',
    TITLE_ALT: 'Detail Promotion',
  },
}
