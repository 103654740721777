import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const ProductSubcategoryScreen = {
  PRODUCT_SUB_CATEGORY: {
    PATH: '/master-data/sub-category',
    TITLE: 'Sub Category',
    TITLE_ALT: ' Sub Category',
    breadcrumbs: defaultBreadcrumbs,
  },
  PRODUCT_SUB_CATEGORY_ADD: {
    PATH: '/master-data/sub-category/add',
    TITLE: 'Add Sub Category',
    TITLE_ALT: 'Add Sub Category',
    breadcrumbs: defaultBreadcrumbs,
  },
  PRODUCT_SUB_CATEGORY_EDIT: {
    PATH: '/master-data/sub-category/edit/:id',
    TITLE: 'Edit Sub Category',
    TITLE_ALT: 'Edit Sub Category',
    breadcrumbs: defaultBreadcrumbs,
  },
  PRODUCT_SUB_CATEGORY_DETAIL: {
    PATH: '/master-data/sub-category/detail/:id',
    TITLE: 'Detail Sub Category',
    TITLE_ALT: 'Detail Sub Category',
    breadcrumbs: defaultBreadcrumbs,
  },
}
