import {ClassValue, clsx} from 'clsx'
import {extendTailwindMerge} from 'tailwind-merge'

type AdditionalThemeGroupIDs = 'fontSize'

export const customTwMerge = extendTailwindMerge<string, AdditionalThemeGroupIDs>({
  extend: {
    classGroups: {
      fontSize: [
        'text-fs-1',
        'text-fs-2',
        'text-fs-3',
        'text-fs-4',
        'text-fs-5',
        'text-fs-6',
        'text-fs-7',
        'text-fs-8',
        'text-fs-9',
        'text-fs-10',
        'text-fs-11',
      ],
    },
  },
})

export const cn = (...inputs: ClassValue[]) => customTwMerge(clsx(inputs))
