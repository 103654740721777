import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const CompanyBranchScreen = {
  COMPANY_BRANCH: {
    PATH: '/master-data/branch',
    TITLE: 'Branch',
    TITLE_ALT: 'Branch',
    breadcrumbs: defaultBreadcrumbs,
  },
  COMPANY_BRANCH_ADD_MAIN: {
    PATH: '/master-data/branch/main/add',
    TITLE: 'Add Branch',
    TITLE_ALT: 'Add Branch',
    breadcrumbs: defaultBreadcrumbs,
  },
  COMPANY_BRANCH_EDIT: {
    PATH: '/master-data/branch/edit/:id',
    TITLE: 'Edit Branch',
    TITLE_ALT: 'Edit Branch',
    breadcrumbs: defaultBreadcrumbs,
  },
  COMPANY_BRANCH_DETAIL: {
    PATH: '/master-data/branch/detail/:id',
    TITLE: 'Detail Branch',
    TITLE_ALT: 'Detail Branch',
    breadcrumbs: defaultBreadcrumbs,
  },
}
