import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosSpecialPriceScreen = {
  POS_SPECIAL_PRICE: {
    PATH: '/master-data/special-price',
    TITLE: 'Special Price',
    TITLE_ALT: 'Special Price',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_SPECIAL_PRICE_ADD: {
    PATH: '/master-data/special-price/add',
    TITLE: 'Add Special Price',
    TITLE_ALT: 'Add Special Price',
    breadcrumbs: defaultBreadcrumbs,
  },
}
