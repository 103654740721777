import {cva} from 'class-variance-authority'
import {FC} from 'react'
import GIcon from 'src/app/components/Libs/Icon/GIcon'
import {cn} from 'src/app/utils/cn-utils'

const toastTheme = cva(
  [
    'transition-opacity',
    'w-full',
    'block',
    'text-fs-9',
    'rounded-lg',
    'mb-4',
    'opacity-0',
    'duration-500',
    'border',
  ],
  {
    variants: {
      scheme: {
        primary: ['bg-primary-light-2', 'text-primary-dark-2', 'border-primary-light-1'],
        success: ['bg-success-light-2', 'text-success-dark-2', 'border-success-light-1'],
        danger: ['bg-danger-light-2', 'text-danger-dark-2', 'border-danger-light-1'],
        warning: ['bg-[#FCF5E3]', 'text-warning-dark-2', 'border-[#C98602]'],
        info: ['bg-info-light-2', 'text-info-dark-2', 'border-info-light-1'],
      },
    },
  }
)

const WarningToast: FC<any> = ({data}) => {
  return (
    <div className={'z-[9999] fixed right-0 left-0 top-10 flex justify-center'}>
      <div className='w-[660px] mx-auto'>
        <div className={cn(toastTheme({scheme: data.scheme}), {'opacity-100': data.shown})}>
          <div className='flex justify-center p-4 break-words'>
            <GIcon className='w-5 h-5 mt-[2px] mr-3' icon={'IconCheckOutlineCircle'} />

            <div className='flex-1'>
              <div className='flex-1 mb-1 font-semibold text-gray-800'>{data.header}</div>
              <div className='flex-1 text-gray-500'>{data.text}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WarningToast
