import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const OrderScreen = {
  PURCHASE_ORDER_LIST: {
    PATH: '/purchasing/order',
    TITLE: 'Purchase Order',
    TITLE_ALT: 'Purchase Order',
    breadcrumbs: defaultBreadcrumbs,
  },
  PURCHASE_ORDER_ADD: {
    PATH: '/purchasing/order/add',
    TITLE: 'Add Purchase Order',
    TITLE_ALT: 'Add Purchase Order',
    breadcrumbs: defaultBreadcrumbs,
  },
  PURCHASE_ORDER_EDIT: {
    PATH: '/purchasing/order/edit/:id',
    TITLE: 'Edit Purchase Order',
    TITLE_ALT: 'Edit Purchase Order',
  },
  PURCHASE_ORDER_DETAIL: {
    PATH: '/purchasing/order/detail/:id',
    TITLE: 'Detail Purchase Order',
    TITLE_ALT: 'Detail Purchase Order',
  },
}
