import {FC} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {HeaderToast} from 'src/app/components/Libs'
import {HeaderToastProvider} from 'src/app/context/ToastContext'
import AuthScreens from './Screens'
import GuestWrapper from './components/GuestWrapper'

const AuthRoutes: FC = () => {
  return (
    <HeaderToastProvider>
      <HeaderToast variant='FULL' />

      <GuestWrapper>
        <Switch>
          <Route
            path={AuthScreens.LOGIN_EMAIL.PATH}
            component={AuthScreens.LOGIN_EMAIL.COMPONENT}
          />
          <Route path={AuthScreens.FORGOT.PATH} component={AuthScreens.FORGOT.COMPONENT} />
          <Route
            path={AuthScreens.CREATE_PASSWORD.PATH}
            component={AuthScreens.CREATE_PASSWORD.COMPONENT}
          />
          <Redirect from='/auth' exact={true} to='/auth/login-email' />
          <Redirect to='/auth/login-email' />
        </Switch>
      </GuestWrapper>
    </HeaderToastProvider>
  )
}

export default AuthRoutes
