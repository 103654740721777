import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const AccountingPaymentMethodScreen = {
  ACCOUNTING_PAYMENT_METHOD_LIST: {
    PATH: '/master-data/payment-method',
    TITLE: 'Payment Method',
    TITLE_ALT: 'Payment Method',
    breadcrumbs: defaultBreadcrumbs,
  },
  ACCOUNTING_PAYMENT_METHOD_ADD: {
    PATH: '/master-data/payment-method/add',
    TITLE: 'Add Payment Method',
    TITLE_ALT: 'Add Payment Method',
    breadcrumbs: defaultBreadcrumbs,
  },
  ACCOUNTING_PAYMENT_METHOD_EDIT: {
    PATH: '/master-data/payment-method/edit/:id',
    TITLE: 'Edit Payment Method',
    TITLE_ALT: 'Edit Payment Method',
    breadcrumbs: defaultBreadcrumbs,
  },
  ACCOUNTING_PAYMENT_METHOD_DETAIL: {
    PATH: '/master-data/payment-method/detail/:id',
    TITLE: 'Detail Payment Method',
    TITLE_ALT: 'Detail Payment Method',
    breadcrumbs: defaultBreadcrumbs,
  },
}
