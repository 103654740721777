import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const ProductUnitScreen = {
  PRODUCT_UNIT_LIST: {
    PATH: '/master-data/unit',
    TITLE: 'Unit List',
    TITLE_ALT: 'Unit List',
    breadcrumbs: defaultBreadcrumbs,
  },
  PRODUCT_UNIT_ADD: {
    PATH: '/master-data/unit/add',
    TITLE: 'Add Unit',
    TITLE_ALT: 'Add Unit',
    breadcrumbs: defaultBreadcrumbs,
  },
  PRODUCT_UNIT_EDIT: {
    PATH: '/master-data/unit/edit/:id',
    TITLE: 'Edit Unit',
    TITLE_ALT: 'Edit Unit',
    breadcrumbs: defaultBreadcrumbs,
  },
  PRODUCT_UNIT_DETAIL: {
    PATH: '/master-data/unit/detail/:id',
    TITLE: 'Detail Unit',
    TITLE_ALT: 'Detail Unit',
    breadcrumbs: defaultBreadcrumbs,
  },
}
