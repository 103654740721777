import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosMenuPackageScreen = {
  POS_MENU_PACKAGE: {
    PATH: '/master-data/menu-package',
    TITLE: 'Menu Package ',
    TITLE_ALT: 'Menu Package',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_MENU_PACKAGE_EDIT: {
    PATH: '/master-data/menu-package/edit/:id',
    TITLE: 'Edit Menu Package ',
    TITLE_ALT: 'Edit Menu Package',
    breadcrumbs: defaultBreadcrumbs,
  },
}
