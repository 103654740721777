import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosCancelReasonScreen = {
  POS_CANCEL_REASON: {
    PATH: '/master-data/cancel-reason',
    TITLE: 'Cancel Reason',
    TITLE_ALT: 'Cancel Reason',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_CANCEL_REASON_ADD: {
    PATH: '/master-data/cancel-reason/add',
    TITLE: 'Add Cancel Reason',
    TITLE_ALT: 'Add Cancel Reason',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_CANCEL_REASON_EDIT: {
    PATH: '/master-data/cancel-reason/edit/:id',
    TITLE: 'Edit Cancel Reason',
    TITLE_ALT: 'Edit Cancel Reason',
  },
  POS_CANCEL_REASON_DETAIL: {
    PATH: '/master-data/cancel-reason/detail/:id',
    TITLE: 'Detail Cancel Reason',
    TITLE_ALT: 'Detail Cancel Reason',
  },
}
