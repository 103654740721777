import {FC} from 'react'
import {Link} from 'react-router-dom'
import {cn} from 'src/app/utils/cn-utils'
import GIcon from '../Icon/GIcon'
import {iconButton} from './IconButton.theme'
import {BaseIconButtonProps, IconButtonProps} from './IconButton.types'

const BaseIconButton: FC<BaseIconButtonProps> = ({loading, icon}) => (
  <div className='flex items-center justify-center'>
    {loading && <GIcon icon='IconLoading' className={`w-5 h-5 animate-spin`} />}
    {!loading && <GIcon icon={icon} className='w-5 h-5' />}
  </div>
)

export const IconButton: FC<IconButtonProps> = (props) => {
  const {colors, icon, className, loading, ...rest} = props
  return (
    <button className={cn(iconButton({colors, className}))} {...rest}>
      <BaseIconButton loading={loading} icon={icon} />
    </button>
  )
}

export const GIconButton: FC<IconButtonProps> = ({href, ...rest}) => {
  if (href)
    return (
      <Link to={href}>
        <IconButton {...rest} />
      </Link>
    )
  return <IconButton {...rest} />
}
