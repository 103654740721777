import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const ProductProductScreen = {
  PRODUCT_PRODUCT_LIST: {
    PATH: '/master-data/product',
    TITLE: 'Master Data Product',
    TITLE_ALT: 'Master Data Product',
    breadcrumbs: defaultBreadcrumbs,
  },
  PRODUCT_PRODUCT_ADD: {
    PATH: '/master-data/product/add',
    TITLE: 'Add Product',
    TITLE_ALT: 'Add Product',
    breadcrumbs: defaultBreadcrumbs,
  },
  PRODUCT_PRODUCT_EDIT: {
    PATH: '/master-data/product/edit/:id',
    TITLE: 'Edit Product',
    TITLE_ALT: 'Edit Product',
    breadcrumbs: defaultBreadcrumbs,
  },
  PRODUCT_PRODUCT_DETAIL: {
    PATH: '/master-data/product/detail/:id',
    TITLE: 'Detail Product',
    TITLE_ALT: 'Detail Product',
    breadcrumbs: defaultBreadcrumbs,
  },
}
