import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const InventoryAdjustmentScreen = {
  INVENTORY_ADJUSTMENT_LIST: {
    PATH: '/inventory/inventory-adjustment',
    TITLE: 'Inventory Adjustment',
    TITLE_ALT: 'Inventory Adjustment',
    breadcrumbs: defaultBreadcrumbs,
  },
  INVENTORY_ADJUSTMENT_ADD: {
    PATH: '/inventory/inventory-adjustment/add',
    TITLE: 'Add Inventory Adjustment',
    TITLE_ALT: 'Add Inventory Adjustment',
    breadcrumbs: defaultBreadcrumbs,
  },
  INVENTORY_ADJUSTMENT_EDIT: {
    PATH: '/inventory/inventory-adjustment/edit/:id',
    TITLE: 'Edit Inventory Adjustment',
    TITLE_ALT: 'Edit Inventory Adjustment',
  },
  INVENTORY_ADJUSTMENT_DETAIL: {
    PATH: '/inventory/inventory-adjustment/detail/:id',
    TITLE: 'Detail Inventory Adjustment',
    TITLE_ALT: 'Detail Inventory Adjustment',
  },
}
