import {defaultBreadcrumbs} from 'src/app/models/screen-prop'
import {UserLogReportScreen} from './pages/UserLogReport/Screen'
import {LoginEvenReportScreen} from './pages/LoginEvenReport/Screen'
import {TransactionLogReportScreen} from './pages/TransactionLogReport/Screen'

const ReportScreen = {
  REPORT: {
    PATH: '/report',
    TITLE: 'Report',
    TITLE_ALT: 'Report',
    breadcrumbs: defaultBreadcrumbs,
  },
  ...UserLogReportScreen,
  ...LoginEvenReportScreen,
  ...TransactionLogReportScreen,
}

export default ReportScreen
