import React, {ComponentProps} from 'react'
import {FormText} from '../Text'
import {validateInputNumber} from 'src/app/utils/input-utils'

type FormNumberProps = Omit<ComponentProps<typeof FormText>, 'onChange'> & {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}
function FormNumber(props: FormNumberProps) {
  return (
    <FormText
      {...props}
      onChange={(e) =>
        validateInputNumber(e.target.value, () => {
          props.onChange(e)
        })
      }
    />
  )
}

export default FormNumber
