import {FC, MouseEventHandler} from 'react'
import {cn} from 'src/app/utils/cn-utils'
import GIcon from '../../Libs/Icon/GIcon'

export interface ModalHeaderProps {
  header?: string
  titleSize?: 'sm' | 'md'
  handleClose?: MouseEventHandler
  isBtnCloseHidden?: boolean
  titleClassName?: string
}

const ModalHeader: FC<ModalHeaderProps> = ({
  header = 'Title',
  handleClose,
  titleSize = 'md',
  isBtnCloseHidden,
  titleClassName,
}) => {
  return (
    <div className='flex items-center justify-between w-full p-6 font-medium border-b border-neutral-30'>
      <div
        className={cn(
          `flex items-center font-bold ${titleClassName}`,
          {'text-fs-6': titleSize === 'md'},
          {'text-fs-7': titleSize === 'sm'}
        )}
      >
        {header}
      </div>

      {/* begin::Close */}
      {!isBtnCloseHidden && (
        <div
          data-testid='close-icon'
          onClick={handleClose}
          className='cursor-pointer text-neutral-60'
        >
          <GIcon icon='IconClose' className='w-6 h-6' />
        </div>
      )}
    </div>
  )
}

export default ModalHeader
