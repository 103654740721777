import React from 'react'
import {Card} from 'src/app/components/Libs'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'
import {imageError} from '../../../utils/broken-image'

const GuestWrapper: React.FC<{withTitle?: boolean; children?: React.ReactNode}> = ({
  withTitle = true,
  children,
}) => {
  return (
    <div
      data-testid='content-wrapper-component'
      className='flex items-center justify-center w-screen h-screen py-24 bg-neutral-10'
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          '/media/backgrounds/BgLogoMakanLeft.svg'
        )}),url(${toAbsoluteUrl('/media/backgrounds/BgLogoMakanRight.svg')})`,
        backgroundPosition: 'top left, bottom right',
        backgroundRepeat: 'no-repeat, no-repeat',
      }}
    >
      <div className='h-max-[688px] w-[504px]'>
        <Card.Root className='flex items-center justify-between h-full py-14 rounded-lg'>
          <img
            src={toAbsoluteUrl('/media/logos/LogoRamen.svg')}
            alt='AIT'
            className='h-20'
            onError={(e) => imageError(e)}
          />
          <div className='text-[#63052B] pt-2 font-bold mb-5 text-[32px]'>CORE</div>
          <div className='w-[356px]'>{children}</div>
        </Card.Root>
      </div>
    </div>
  )
}

export default GuestWrapper
