import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const ItemReceiptScreen = {
  ITEM_RECEIPT_LIST: {
    PATH: '/inventory/item-receipt',
    TITLE: 'Item Receipt',
    TITLE_ALT: 'Item Receipt',
    breadcrumbs: defaultBreadcrumbs,
  },
  ITEM_RECEIPT_ADD: {
    PATH: '/inventory/item-receipt/add',
    TITLE: 'Add Item Receipt',
    TITLE_ALT: 'Add Item Receipt',
    breadcrumbs: defaultBreadcrumbs,
  },
  ITEM_RECEIPT_EDIT: {
    PATH: '/inventory/item-receipt/edit/:id',
    TITLE: 'Edit Item Receipt',
    TITLE_ALT: 'Edit Item Receipt',
  },
  ITEM_RECEIPT_DETAIL: {
    PATH: '/inventory/item-receipt/detail/:id',
    TITLE: 'Detail Item Receipt',
    TITLE_ALT: 'Detail Item Receipt',
  },
}
