import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const ProductBomScreen = {
  PRODUCT_BILL_OF_MATERIAL: {
    PATH: '/master-data/bill-of-material',
    TITLE: 'Bill Of Material',
    TITLE_ALT: 'Bill Of Material',
    breadcrumbs: defaultBreadcrumbs,
  },
  PRODUCT_BILL_OF_MATERIAL_ADD: {
    PATH: '/master-data/bill-of-material/add',
    TITLE: 'Add Bill Of Material',
    TITLE_ALT: 'Add Bill Of Material',
    breadcrumbs: defaultBreadcrumbs,
  },
  PRODUCT_BILL_OF_MATERIAL_EDIT: {
    PATH: '/master-data/bill-of-material/edit/:id',
    TITLE: 'Edit Bill Of Material',
    TITLE_ALT: 'Edit Bill Of Material',
    breadcrumbs: defaultBreadcrumbs,
  },
  PRODUCT_BILL_OF_MATERIAL_DETAIL: {
    PATH: '/master-data/bill-of-material/detail/:id',
    TITLE: 'Detail Bill Of Material',
    TITLE_ALT: 'Detail Bill Of Material',
    breadcrumbs: defaultBreadcrumbs,
  },
}
