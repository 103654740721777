import {cva} from 'class-variance-authority'

export const toastTheme = cva(
  [
    'transition-opacity',
    'w-full',
    'block',
    'text-fs-9',
    'rounded-lg',
    'mb-4',
    'opacity-0',
    'duration-500',
    'border',
  ],
  {
    variants: {
      scheme: {
        primary: ['bg-primary-light-2', 'text-primary-dark-2', 'border-primary-light-1'],
        success: ['bg-success-light-2', 'text-success-dark-2', 'border-success-light-1'],
        danger: ['bg-danger-light-2', 'text-danger-dark-2', 'border-danger-light-1'],
        warning: ['bg-warning-light-2', 'text-warning-dark-2', 'border-warning-light-1'],
        info: ['bg-info-light-2', 'text-info-dark-2', 'border-info-light-1'],
      },
    },
  }
)
