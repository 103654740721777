import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import store from '../../../setup/redux/Store'
import translationDE from './messages/de.json'
import translationEN from './messages/en.json'
import translationFR from './messages/fr.json'
import translationID from './messages/id.json'
import translationJA from './messages/ja.json'
import translationKO from './messages/ko.json'
import translationPT from './messages/pt.json'

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  id: {
    translation: translationID,
  },
  ko: {
    translation: translationKO,
  },
  fr: {
    translation: translationFR,
  },
  pt: {
    translation: translationPT,
  },
  ja: {
    translation: translationJA,
  },
}

const {
  localization: {translation},
} = store().getState()

i18n.use(initReactI18next).init({
  resources,
  lng: translation,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
