import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const RequestScreen = {
  PURCHASE_REQUEST_LIST: {
    PATH: '/purchasing/request',
    TITLE: 'Purchase Request',
    TITLE_ALT: 'Purchase Request',
    breadcrumbs: defaultBreadcrumbs,
  },
  PURCHASE_REQUEST_ADD: {
    PATH: '/purchasing/request/add',
    TITLE: 'Add Purchase Request',
    TITLE_ALT: 'Add Purchase Request',
    breadcrumbs: defaultBreadcrumbs,
  },
  PURCHASE_REQUEST_EDIT: {
    PATH: '/purchasing/request/edit/:id',
    TITLE: 'Edit Purchase Request',
    TITLE_ALT: 'Edit Purchase Request',
  },
  PURCHASE_REQUEST_DETAIL: {
    PATH: '/purchasing/request/detail/:id',
    TITLE: 'Detail Purchase Request',
    TITLE_ALT: 'Detail Purchase Request',
  },
}
