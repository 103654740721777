import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosMenuExtraScreen = {
  POS_MENU_EXTRA: {
    PATH: '/master-data/menu-extra',
    TITLE: 'Menu Extra',
    TITLE_ALT: 'Menu Extra',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_MENU_EXTRA_ADD: {
    PATH: '/master-data/menu-extra/add',
    TITLE: 'Add Menu Extra',
    TITLE_ALT: 'Add Menu Extra',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_MENU_EXTRA_EDIT: {
    PATH: '/master-data/menu-extra/edit/:id',
    TITLE: 'Edit Menu Extra',
    TITLE_ALT: 'Edit Menu Extra',
    breadcrumbs: defaultBreadcrumbs,
  },
}
