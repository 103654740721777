import React, {createContext, useCallback, useContext, useEffect, useMemo} from 'react'

export interface LayoutContextModel {
  setLayout: () => void
}

const LayoutContext = createContext<LayoutContextModel>({
  setLayout: () => {},
})

const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex')
  }
}

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none')
  }
}

const LayoutProvider: React.FC<{children?: React.ReactNode}> = ({children}) => {
  const setLayout = useCallback(() => {
    enableSplashScreen()

    setTimeout(() => {
      disableSplashScreen()
    }, 3000)
  }, [])

  const value: LayoutContextModel = useMemo(
    () => ({
      setLayout,
    }),
    [setLayout]
  )

  useEffect(() => {
    let timeout: number

    timeout = window.setTimeout(() => {
      disableSplashScreen()
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
    //   disableSplashScreen()
  }, [])

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export {LayoutContext, LayoutProvider}

export function useLayout() {
  return useContext(LayoutContext)
}
