import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosUserRoleScreen = {
  POS_USER_ROLE: {
    PATH: '/master-data/pos-user-role',
    TITLE: 'POS User Role',
    TITLE_ALT: 'POS User Role',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_USER_ROLE_ADD: {
    PATH: '/master-data/pos-user-role/add',
    TITLE: 'Add POS User Role',
    TITLE_ALT: 'Add POS User Role',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_USER_ROLE_EDIT: {
    PATH: '/master-data/pos-user-role/edit/:id',
    TITLE: 'Edit POS User Role',
    TITLE_ALT: 'Edit POS User Role',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_USER_ROLE_DETAIL: {
    PATH: '/master-data/pos-user-role/detail/:id',
    TITLE: 'Detail POS User Role',
    TITLE_ALT: 'Detail POS User Role',
    breadcrumbs: defaultBreadcrumbs,
  },
}
