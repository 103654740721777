import {defaultBreadcrumbs} from 'src/app/models/screen-prop'
import {AccountingPaymentMethodScreen} from './pages/ACCOUNTING_PaymentMethod/Screen'
import {CompanyBranchScreen} from './pages/COMPANY_Branch/Screen'
import {CompanyBrandScreen} from './pages/COMPANY_Brand/Screen'
import {CompanyRoleScreen} from './pages/COMPANY_Role/Screen'
import {CompanyUserScreen} from './pages/COMPANY_User/Screen'
import {PosCancelReasonScreen} from './pages/POS_CancelReason/Screen'
import {PosMenuScreen} from './pages/POS_Menu/Screen'
import {PosMenuCategoryScreen} from './pages/POS_MenuCategory/Screen'
import {PosMenuExtraScreen} from './pages/POS_MenuExtra/Screen'
import {PosMenuPackageScreen} from './pages/POS_MenuPackage/Screen'
import {PosMenuPriceScreen} from './pages/POS_MenuPrice/Screen'
import {PosMenuTemplateScreen} from './pages/POS_MenuTemplate/Screen'
import {PosNoteCategoryScreen} from './pages/POS_NoteCategory/Screen'
import {PosPromotionScreen} from './pages/POS_Promotion/Screen'
import {PosSalesModeScreen} from './pages/POS_SalesMode/Screen'
import {PosSpecialPriceScreen} from './pages/POS_SpecialPrice/Screen'
import {PosStationScreen} from './pages/POS_Station/Screen'
import {PosTableManagementScreen} from './pages/POS_TableManagement/Screen'
import {PosTableSectionScreen} from './pages/POS_TableSection/Screen'
import {PosUserRoleScreen} from './pages/POS_UserRole/Screen'
import {PosVisitorTypeScreen} from './pages/POS_VisitorType/Screen'
import {PosVoucherScreen} from './pages/POS_Voucher/Screen'
import {ProductBomScreen} from './pages/PRODUCT_BOM/Screen'
import {ProductCategoryScreen} from './pages/PRODUCT_Category/Screen'
import {ProductProductScreen} from './pages/PRODUCT_Product/Screen'
import {ProductSubcategoryScreen} from './pages/PRODUCT_SubCategory/Screen'
import {ProductUnitScreen} from './pages/PRODUCT_Unit/Screen'
import {PosNotificationScreen} from './pages/POS_Notification/Screen'

const MasterDataScreen = {
  MASTER_DATA: {
    PATH: '/master-data',
    TITLE: 'Master Data',
    TITLE_ALT: 'Master Data',
    breadcrumbs: defaultBreadcrumbs,
  },
  ...AccountingPaymentMethodScreen,

  ...CompanyBranchScreen,
  ...CompanyBrandScreen,
  ...CompanyRoleScreen,
  ...CompanyUserScreen,

  ...PosMenuScreen,
  ...PosMenuCategoryScreen,
  ...PosMenuExtraScreen,
  ...PosMenuPackageScreen,
  ...PosMenuPackageScreen,
  ...PosMenuTemplateScreen,
  ...PosNoteCategoryScreen,
  ...PosSalesModeScreen,
  ...PosSpecialPriceScreen,
  ...PosTableManagementScreen,
  ...PosTableSectionScreen,
  ...PosUserRoleScreen,
  ...PosVoucherScreen,
  ...PosPromotionScreen,
  ...PosStationScreen,
  ...PosCancelReasonScreen,
  ...PosVisitorTypeScreen,
  ...PosMenuPriceScreen,
  ...PosNotificationScreen,

  ...ProductBomScreen,
  ...ProductCategoryScreen,
  ...ProductProductScreen,
  ...ProductSubcategoryScreen,
  ...ProductUnitScreen,
}

export default MasterDataScreen
