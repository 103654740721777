import {VariantProps} from 'class-variance-authority'
import {ReactNode} from 'react'
import {GIconProps} from '../Icon/GIcon'
import {filledButton, ghostButton, outlinedButton, unstyledButton} from './Button.theme'

interface ButtonTemplateProps {
  loading?: boolean
  startIcon?: GIconProps
  endIcon?: GIconProps
  href?: string
  children: ReactNode
}

export interface BaseButtonProps extends Omit<ButtonTemplateProps, 'href'> {
  size?: 'small' | 'medium' | 'large'
}

export interface FilledButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color' | 'children'>,
    VariantProps<typeof filledButton>,
    ButtonTemplateProps {
  variant?: 'FILLED'
}

export interface OutlinedButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color' | 'children'>,
    VariantProps<typeof outlinedButton>,
    ButtonTemplateProps {
  variant?: 'OUTLINED'
}

export interface GhostButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color' | 'children'>,
    VariantProps<typeof ghostButton>,
    ButtonTemplateProps {
  variant?: 'GHOST'
}

export interface UnstyledButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color' | 'children'>,
    VariantProps<typeof unstyledButton>,
    ButtonTemplateProps {
  variant?: 'UNSTYLED'
}

export enum IconSizes {
  large = 'w-5 h-5',
  medium = 'w-4 h-4',
  small = 'w-[14px] h-[14px]',
}

export type GeneratedButtonProps =
  | FilledButtonProps
  | OutlinedButtonProps
  | GhostButtonProps
  | UnstyledButtonProps
