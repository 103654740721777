const ProfileScreens = {
  PROFILE: {
    PATH: '/profile',
    TITLE: 'Profile',
    TITLE_ALT: 'Profile',
  },
  RESET_PASSWORD: {
    PATH: '/profile/reset-password',
    TITLE: 'Reset Password',
    TITLE_ALT: 'Reset Password',
  },
}

export default ProfileScreens
