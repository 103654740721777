import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosTableManagementScreen = {
  POS_TABLE_MANAGEMENT: {
    PATH: '/master-data/table-management',
    TITLE: 'Table Management',
    TITLE_ALT: 'Table Management',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_TABLE_MANAGEMENT_ADD: {
    PATH: '/master-data/table-management/add',
    TITLE: 'Add Table Management',
    TITLE_ALT: 'Add Table Management ',
    breadcrumbs: defaultBreadcrumbs,
  },
}
