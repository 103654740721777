import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const DeliveryOrderScreen = {
  DELIVERY_ORDER_LIST: {
    PATH: '/inventory/delivery-order',
    TITLE: 'Delivery Order',
    TITLE_ALT: 'Delivery Order',
    breadcrumbs: defaultBreadcrumbs,
  },
  DELIVERY_ORDER_ADD: {
    PATH: '/inventory/delivery-order/add',
    TITLE: 'Add Delivery Order',
    TITLE_ALT: 'Add Delivery Order',
    breadcrumbs: defaultBreadcrumbs,
  },
  DELIVERY_ORDER_EDIT: {
    PATH: '/inventory/delivery-order/edit/:id',
    TITLE: 'Edit Delivery Order',
    TITLE_ALT: 'Edit Delivery Order',
  },
  DELIVERY_ORDER_DETAIL: {
    PATH: '/inventory/delivery-order/detail/:id',
    TITLE: 'Detail Delivery Order',
    TITLE_ALT: 'Detail Delivery Order',
  },
}
