import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../components/Layout/FallbackView'
import DashboardScreens from '../modules/dashboard/Screens'
import InventoryScreen from '../modules/inventory/InventoryScreen'
import MasterDataScreen from '../modules/master-data/MasterDataScreen'
import UserRoleScreens from '../modules/permissions/Screens'
import ProfileScreens from '../modules/profile/Screens'
import PurchasingScreen from '../modules/purchasing/PurchasingScreen'
import ReportScreen from '../modules/report/ReportScreen'

export function PrivateRoutes() {
  const DashboardPage = lazy(() => import('../modules/dashboard/DashboardRoutes'))
  const UserRolePage = lazy(() => import('../modules/permissions/UserRoleRoutes'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfileRoutes'))
  const PurchasingPage = lazy(() => import('../modules/purchasing/PurchasingRoutes'))
  const InventoryPage = lazy(() => import('../modules/inventory/InventoryRoutes'))
  const MasterDataPage = lazy(() => import('../modules/master-data/MasterDataRoutes'))
  const ReportPage = lazy(() => import('../modules/report/ReportRoutes'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path={DashboardScreens.DASHBOARD.PATH} component={DashboardPage} />
        <Route path={UserRoleScreens.USER_ROLE.PATH} component={UserRolePage} />
        <Route path={ProfileScreens.PROFILE.PATH} component={ProfilePage} />
        <Route path={PurchasingScreen.PURCHASING.PATH} component={PurchasingPage} />
        <Route path={InventoryScreen.INVENTORY.PATH} component={InventoryPage} />
        <Route path={MasterDataScreen.MASTER_DATA.PATH} component={MasterDataPage} />
        <Route path={ReportScreen.REPORT.PATH} component={ReportPage} />
        <Redirect from='/auth' to='/dashboard/live-view' />
        <Redirect exact from='/' to='/dashboard/live-view' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
