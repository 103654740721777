import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosMenuCategoryScreen = {
  POS_MENU_CATEGORY: {
    PATH: '/master-data/menu-category',
    TITLE: 'Menu Category',
    TITLE_ALT: 'Menu Category',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_MENU_CATEGORY_ADD: {
    PATH: '/master-data/menu-category/add',
    TITLE: 'Add Menu Category',
    TITLE_ALT: 'Add Menu Category',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_MENU_CATEGORY_EDIT: {
    PATH: '/master-data/menu-category/edit/:id',
    TITLE: 'Edit Menu Category',
    TITLE_ALT: 'Edit Menu Category',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_MENU_CATEGORY_DETAIL: {
    PATH: '/master-data/menu-category/detail/:id',
    TITLE: 'Detail Menu Category',
    TITLE_ALT: 'Detail Menu Category',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_MENU_CATEGORY_EDIT_ORDER_LIST: {
    PATH: '/master-data/menu-category/edit-order',
    TITLE: 'Sort Menu Category',
    TITLE_ALT: 'Sort Menu Category',
    breadcrumbs: defaultBreadcrumbs,
  },
}
