import {FC} from 'react'
import {useMasterLayout} from 'src/app/context/MasterContext'

const OverlayWrapper: FC = () => {
  const {minimize, setMinimize} = useMasterLayout()

  if (minimize) return <></>

  return (
    <div
      data-testid='overlay-wrapper'
      onClick={() => setMinimize(!minimize)}
      className='fixed top-0 right-0 left-0 bottom-0 bg-black/20 z-[101] lg:hidden'
    ></div>
  )
}

export default OverlayWrapper
