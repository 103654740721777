import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosTableSectionScreen = {
  POS_TABLE_SECTION: {
    PATH: '/master-data/table-section',
    TITLE: 'Table Section',
    TITLE_ALT: 'Table Section',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_TABLE_SECTION_ADD: {
    PATH: '/master-data/table-section/add',
    TITLE: 'Add Table Section',
    TITLE_ALT: 'Add Table Section',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_TABLE_SECTION_DETAIL: {
    PATH: '/master-data/table-section/detail/:id',
    TITLE: 'Detail Table Section',
    TITLE_ALT: 'Detail Table Section',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_TABLE_SECTION_EDIT: {
    PATH: '/master-data/table-section/edit/:id',
    TITLE: 'Edit Table Section',
    TITLE_ALT: 'Edit Table Section',
    breadcrumbs: defaultBreadcrumbs,
  },
}
