import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosSalesModeScreen = {
  POS_SALES_MODE: {
    PATH: '/master-data/sales-mode',
    TITLE: 'Visit Purpose / Sales Mode',
    TITLE_ALT: 'Visit Purpose / Sales Mode',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_SALES_MODE_ADD: {
    PATH: '/master-data/sales-mode/add',
    TITLE: 'Add Visit Purpose / Sales Mode',
    TITLE_ALT: 'Add Visit Purpose / Sales Mode',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_SALES_MODE_EDIT: {
    PATH: '/master-data/sales-mode/edit/:id',
    TITLE: 'Edit Visit Purpose / Sales Mode',
    TITLE_ALT: 'Edit Visit Purpose / Sales Mode',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_SALES_MODE_DETAIL: {
    PATH: '/master-data/sales-mode/detail/:id',
    TITLE: 'Detail Visit Purpose / Sales Mode',
    TITLE_ALT: 'Detail Visit Purpose / Sales Mode',
    breadcrumbs: defaultBreadcrumbs,
  },
}
