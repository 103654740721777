import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const TransactionLogReportScreen = {
  TRANSCTION_LOG_REPORT: {
    PATH: '/report/trasanction-log-report',
    TITLE: 'Transaction Log Report',
    TITLE_ALT: 'Transaction Log Report',
    breadcrumbs: defaultBreadcrumbs,
  },
}
