import {cva} from 'class-variance-authority'

export const modalTheme = cva(
  [
    'flex',
    'items-start',
    'rounded',
    'relative',
    'rounded-lg',
    'bg-white',
    'flex-col',
    'min-w-0',
    'dark:bg-inverse-primary-light-2',
  ],
  {
    variants: {
      size: {
        sm: ['w-[400px]'],
        md: ['w-[600px]'],
        lg: ['w-[720px]'],
        xl: ['w-[900px]'],
      },
    },
    compoundVariants: [
      {
        size: 'md',
      },
    ],
    defaultVariants: {
      size: 'md',
    },
  }
)
