import React, {FC, createContext, useContext, useEffect, useMemo, useState} from 'react'

export interface MasterContextModel {
  minimize?: boolean
  setMinimize: (_value: boolean) => void
  currentTheme?: string
  changeCurrentTheme: (_value: 'light' | 'dark') => void
}

const MasterContext = createContext<MasterContextModel>({
  minimize: false,
  setMinimize: (_value: boolean) => {},
  currentTheme: '',
  changeCurrentTheme: (_value: 'light' | 'dark') => {},
})

const MasterProvider: FC<{children?: React.ReactNode}> = ({children}) => {
  const [minimize, setMinimize] = useState<boolean>(false)
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light')

  const changeCurrentTheme = (newTheme: 'light' | 'dark') => {
    setTheme(newTheme)
    localStorage.setItem('theme', newTheme)
  }

  useEffect(() => {
    if (theme === 'light') document.body.classList.remove('dark')
    else document.body.classList.add('dark')
  }, [theme])

  const value: MasterContextModel = useMemo(
    () => ({
      minimize,
      setMinimize,
      currentTheme: theme,
      changeCurrentTheme,
    }),
    [minimize, theme]
  )

  return <MasterContext.Provider value={value}>{children}</MasterContext.Provider>
}

function useMasterLayout() {
  return useContext(MasterContext)
}

export {MasterProvider, useMasterLayout}
