import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const ItemFulfillmentScreen = {
  ITEM_FULFILLMENT_LIST: {
    PATH: '/inventory/item-fulfillment',
    TITLE: 'Item Fulfillment',
    TITLE_ALT: 'Item Fulfillment',
    breadcrumbs: defaultBreadcrumbs,
  },
  ITEM_FULFILLMENT_ADD: {
    PATH: '/inventory/item-fulfillment/add',
    TITLE: 'Add Item Fulfillment',
    TITLE_ALT: 'Add Item Fulfillment',
    breadcrumbs: defaultBreadcrumbs,
  },
  ITEM_FULFILLMENT_EDIT: {
    PATH: '/inventory/item-fulfillment/edit/:id',
    TITLE: 'Edit Item Fulfillment',
    TITLE_ALT: 'Edit Item Fulfillment',
  },
  ITEM_FULFILLMENT_DETAIL: {
    PATH: '/inventory/item-fulfillment/detail/:id',
    TITLE: 'Detail Item Fulfillment',
    TITLE_ALT: 'Detail Item Fulfillment',
  },
}
