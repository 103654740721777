import {useEffect, useRef, useState} from 'react'

export const useClickOutside = (initialIsVisible: boolean) => {
  const [isShow, setIsShow] = useState<boolean>(initialIsVisible)
  const clickOutsideRef = useRef<any>(null)

  const handleClickOutside = (event: any) => {
    if (clickOutsideRef.current && !clickOutsideRef.current.contains(event.target)) {
      setIsShow(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return [clickOutsideRef, isShow, setIsShow] as const
}
