import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosNotificationScreen = {
  POS_NOTIFICATION: {
    PATH: '/master-data/notification',
    TITLE: 'POS Notification',
    TITLE_ALT: 'POS Notification',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_NOTIFICATION_ADD: {
    PATH: '/master-data/notification/add',
    TITLE: 'Add Notification',
    TITLE_ALT: 'Add Notification',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_NOTIFICATION_EDIT: {
    PATH: '/master-data/notification/edit/:id',
    TITLE: 'Edit Notification',
    TITLE_ALT: 'Edit Notification',
  },
  POS_NOTIFICATION_DETAIL: {
    PATH: '/master-data/notification/detail/:id',
    TITLE: 'Detail Notification',
    TITLE_ALT: 'Detail Notification',
  },
}
