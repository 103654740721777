export const Wording = {
  Button: {
    Save: 'Save',
    Cancel: 'Cancel',
    Delete: 'Delete',
    Back: 'Back',
    Add: 'Add',
    Yes: 'Exit',
    Stay: 'Stay',
    Ok: 'Ok',
    Sure: 'Sure',
    AddUser: 'Add User',
  },
  Status: {
    Active: 'Active',
    Inactive: 'Inactive',
  },
  Gender: {
    Male: 'Male',
  },
}
