import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosNoteCategoryScreen = {
  POS_NOTE_CATEGORY: {
    PATH: '/master-data/note-category',
    TITLE: 'Note Category',
    TITLE_ALT: 'Note Category',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_NOTE_CATEGORY_ADD: {
    PATH: '/master-data/note-category/add',
    TITLE: 'Add Note Category',
    TITLE_ALT: 'Add Note Category',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_NOTE_CATEGORY_EDIT: {
    PATH: '/master-data/note-category/edit/:id',
    TITLE: 'Edit Note Category',
    TITLE_ALT: 'Edit Note Category',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_NOTE_CATEGORY_DETAIL: {
    PATH: '/master-data/note-category/detail/:id',
    TITLE: 'Detail Note Category',
    TITLE_ALT: 'Detail Note Category',
    breadcrumbs: defaultBreadcrumbs,
  },
}
