import {FormikProps} from 'formik'
import {useCallback} from 'react'
import {useHeaderToast} from '../context/ToastContext'
import {getErrorMessage, getErrors} from '../utils/api-utils'
import {useOnline} from './online-hook'

export interface IErrorQuery {
  errorTemp: (e: unknown) => void
  errorComplete: (e: unknown, formik?: FormikProps<unknown>) => void
}

export function useErrorQuery() {
  const {addPageToasts} = useHeaderToast()
  const {isOnline, errorOffline} = useOnline()

  const errorTemp = useCallback(
    (e: unknown) => {
      if (isOnline) addPageToasts({scheme: 'danger', text: getErrorMessage(e, true)})
      else addPageToasts({scheme: 'danger', text: errorOffline})
    },
    [addPageToasts, errorOffline, isOnline]
  )

  const errorComplete = useCallback(
    (e: unknown, formik?: FormikProps<unknown>) => {
      if (isOnline) {
        addPageToasts({scheme: 'danger', text: getErrorMessage(e, true)})

        const detailError = getErrors(e).detailed

        Object.entries(detailError).forEach(([key, value]) => {
          const removedValue = ['created_at', 'updated_at', 'deleted_at']
          if (removedValue.includes(key)) return

          if (['date'].includes(key)) {
            formik?.setFieldError('start_date', value)
            formik?.setFieldError('end_date', value)
          }

          formik?.setFieldError(`${key}`, value)
        })
      } else addPageToasts({scheme: 'danger', text: errorOffline})
    },
    [addPageToasts, errorOffline, isOnline]
  )

  return {errorTemp, errorComplete}
}
