import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosMenuPriceScreen = {
  POS_MENU_PRICE: {
    PATH: '/master-data/menu-price',
    TITLE: 'Menu Price',
    TITLE_ALT: 'Menu Price',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_MENU_PRICE_ADD: {
    PATH: '/master-data/menu-price/add',
    TITLE: 'Add Menu Price',
    TITLE_ALT: 'Add Menu Price',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_MENU_PRICE_EDIT: {
    PATH: '/master-data/menu-price/edit/:id',
    TITLE: 'Edit Menu Price',
    TITLE_ALT: 'Edit Menu Price',
  },
  POS_MENU_PRICE_DETAIL: {
    PATH: '/master-data/menu-price/detail/:id',
    TITLE: 'Detail Menu Price',
    TITLE_ALT: 'Detail Menu Price',
  },
}
