import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const GoodReceiptScreen = {
  GOOD_RECEIPT_LIST: {
    PATH: '/inventory/good-receipt',
    TITLE: 'Good Receipt',
    TITLE_ALT: 'Good Receipt',
    breadcrumbs: defaultBreadcrumbs,
  },
  GOOD_RECEIPT_ADD: {
    PATH: '/inventory/good-receipt/add',
    TITLE: 'Add Good Receipt',
    TITLE_ALT: 'Add Good Receipt',
    breadcrumbs: defaultBreadcrumbs,
  },
  GOOD_RECEIPT_EDIT: {
    PATH: '/inventory/good-receipt/edit/:id',
    TITLE: 'Edit Good Receipt',
    TITLE_ALT: 'Edit Good Receipt',
  },
  GOOD_RECEIPT_DETAIL: {
    PATH: '/inventory/good-receipt/detail/:id',
    TITLE: 'Detail Good Receipt',
    TITLE_ALT: 'Detail Good Receipt',
  },
}
