import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const CompanyUserScreen = {
  COMPANY_USER: {
    PATH: '/master-data/user',
    TITLE: 'User List',
    TITLE_ALT: 'User',
    breadcrumbs: defaultBreadcrumbs,
  },
  COMPANY_USER_ADD: {
    PATH: '/master-data/user/add',
    TITLE: 'Add User',
    TITLE_ALT: 'Add User',
    breadcrumbs: defaultBreadcrumbs,
  },
  COMPANY_USER_EDIT: {
    PATH: '/master-data/user/edit/:id',
    TITLE: 'Edit User',
    TITLE_ALT: 'Edit User',
    breadcrumbs: defaultBreadcrumbs,
  },
  COMPANY_USER_DETAIL: {
    PATH: '/master-data/user/detail/:id',
    TITLE: 'Detail User',
    TITLE_ALT: 'Detail User',
    breadcrumbs: defaultBreadcrumbs,
  },
}
