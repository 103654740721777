import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const CompanyBrandScreen = {
  COMPANY_BRAND: {
    PATH: '/master-data/brand',
    TITLE: 'Brand',
    TITLE_ALT: 'Brand',
    breadcrumbs: defaultBreadcrumbs,
  },
  COMPANY_BRAND_ADD: {
    PATH: '/master-data/brand/add',
    TITLE: 'Add Brand',
    TITLE_ALT: 'Add Brand',
    breadcrumbs: defaultBreadcrumbs,
  },
  COMPANY_BRAND_EDIT: {
    PATH: '/master-data/brand/edit/:id',
    TITLE: 'Edit Brand',
    TITLE_ALT: 'Edit Brand',
    breadcrumbs: defaultBreadcrumbs,
  },
  COMPANY_BRAND_DETAIL: {
    PATH: '/master-data/brand/detail/:id',
    TITLE: 'Detail Brand',
    TITLE_ALT: 'Detail Brand',
    breadcrumbs: defaultBreadcrumbs,
  },
}
