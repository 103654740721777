import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const TransferOrderScreen = {
  TRANSFER_ORDER_LIST: {
    PATH: '/inventory/transfer-order',
    TITLE: 'Transfer Order',
    TITLE_ALT: 'Transfer Order',
    breadcrumbs: defaultBreadcrumbs,
  },
  TRANSFER_ORDER_ADD: {
    PATH: '/inventory/transfer-order/add',
    TITLE: 'Add Transfer Order',
    TITLE_ALT: 'Add Transfer Order',
    breadcrumbs: defaultBreadcrumbs,
  },
  TRANSFER_ORDER_EDIT: {
    PATH: '/inventory/transfer-order/edit/:id',
    TITLE: 'Edit Transfer Order',
    TITLE_ALT: 'Edit Transfer Order',
  },
  TRANSFER_ORDER_DETAIL: {
    PATH: '/inventory/transfer-order/detail/:id',
    TITLE: 'Detail Transfer Order',
    TITLE_ALT: 'Detail Transfer Order',
  },
}
