import {VariantProps} from 'class-variance-authority'
import {ReactNode} from 'react'
import {GIconProps} from '../Icon/GIcon'
import {alertTheme} from './Alert.theme'

export enum GAlertIcon {
  primary = 'IconCheckCircle',
  danger = 'IconExclamation',
  warning = 'IconWarning',
  info = 'IconInfo',
  success = 'IconPaperPlane',
}

export interface GAlertProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color' | 'children'>,
    VariantProps<typeof alertTheme> {
  children?: ReactNode
  icon?: GIconProps
  textClassname?: string
  iconClassname?: string
  className?: string
}
